import React from "react";
import ImageWrapper from "./ImageWrapper";

function CmsImage({ component, page_id = "" }) {
  var componentStyle =
    typeof component.options.image != "undefined"
      ? {
          backgroundImage: `url(${component.options.image.url})`,
        }
      : {};

  return (
    <div className={`h-full image`} id={`${component.id ?? ""}`} data-aos='fade-up'>
      <div
        className={`imageContainer`}
        // dangerouslySetInnerHTML={{ __html: component.options.content }}
        // style={componentStyle}
        >
        {component.options.image.url != "" ? (
            <ImageWrapper
              alt=""
              src={component.options.image.url}
              blur={component.options.image.urlp}
              layout="intrinsic"
              width={component.options.image.width}
              height={component.options.image.height}
              // priority={true}
            />
        ) : (
          ""
        )}
      </div>
      {component.id == 'screenshot' ? 
      <>
        <div className="absolute hidden md:block bottom-0 left-16 z-[-1]">
          <svg
            width="134"
            height="106"
            viewBox="0 0 134 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="1.66667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 1.66667 104)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="104"
              r="1.66667"
              transform="rotate(-90 16.3333 104)"
              fill="white"
            />
            <circle
              cx="31"
              cy="104"
              r="1.66667"
              transform="rotate(-90 31 104)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 45.6667 104)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="104"
              r="1.66667"
              transform="rotate(-90 60.3333 104)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 88.6667 104)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 117.667 104)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 74.6667 104)"
              fill="white"
            />
            <circle
              cx="103"
              cy="104"
              r="1.66667"
              transform="rotate(-90 103 104)"
              fill="white"
            />
            <circle
              cx="132"
              cy="104"
              r="1.66667"
              transform="rotate(-90 132 104)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="89.3333"
              r="1.66667"
              transform="rotate(-90 1.66667 89.3333)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="89.3333"
              r="1.66667"
              transform="rotate(-90 16.3333 89.3333)"
              fill="white"
            />
            <circle
              cx="31"
              cy="89.3333"
              r="1.66667"
              transform="rotate(-90 31 89.3333)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="89.3333"
              r="1.66667"
              transform="rotate(-90 45.6667 89.3333)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 60.3333 89.3338)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 88.6667 89.3338)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 117.667 89.3338)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 74.6667 89.3338)"
              fill="white"
            />
            <circle
              cx="103"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 103 89.3338)"
              fill="white"
            />
            <circle
              cx="132"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 132 89.3338)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="74.6673"
              r="1.66667"
              transform="rotate(-90 1.66667 74.6673)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="31.0003"
              r="1.66667"
              transform="rotate(-90 1.66667 31.0003)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 16.3333 74.6668)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="31.0003"
              r="1.66667"
              transform="rotate(-90 16.3333 31.0003)"
              fill="white"
            />
            <circle
              cx="31"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 31 74.6668)"
              fill="white"
            />
            <circle
              cx="31"
              cy="31.0003"
              r="1.66667"
              transform="rotate(-90 31 31.0003)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 45.6667 74.6668)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="31.0003"
              r="1.66667"
              transform="rotate(-90 45.6667 31.0003)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 60.3333 74.6668)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 60.3333 31.0001)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 88.6667 74.6668)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 88.6667 31.0001)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 117.667 74.6668)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 117.667 31.0001)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 74.6667 74.6668)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 74.6667 31.0001)"
              fill="white"
            />
            <circle
              cx="103"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 103 74.6668)"
              fill="white"
            />
            <circle
              cx="103"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 103 31.0001)"
              fill="white"
            />
            <circle
              cx="132"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 132 74.6668)"
              fill="white"
            />
            <circle
              cx="132"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 132 31.0001)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 1.66667 60.0003)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 1.66667 16.3336)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 16.3333 60.0003)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 16.3333 16.3336)"
              fill="white"
            />
            <circle
              cx="31"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 31 60.0003)"
              fill="white"
            />
            <circle
              cx="31"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 31 16.3336)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 45.6667 60.0003)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 45.6667 16.3336)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 60.3333 60.0003)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 60.3333 16.3336)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 88.6667 60.0003)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 88.6667 16.3336)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 117.667 60.0003)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 117.667 16.3336)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 74.6667 60.0003)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 74.6667 16.3336)"
              fill="white"
            />
            <circle
              cx="103"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 103 60.0003)"
              fill="white"
            />
            <circle
              cx="103"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 103 16.3336)"
              fill="white"
            />
            <circle
              cx="132"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 132 60.0003)"
              fill="white"
            />
            <circle
              cx="132"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 132 16.3336)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="45.3336"
              r="1.66667"
              transform="rotate(-90 1.66667 45.3336)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="1.66683"
              r="1.66667"
              transform="rotate(-90 1.66667 1.66683)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="45.3336"
              r="1.66667"
              transform="rotate(-90 16.3333 45.3336)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="1.66683"
              r="1.66667"
              transform="rotate(-90 16.3333 1.66683)"
              fill="white"
            />
            <circle
              cx="31"
              cy="45.3336"
              r="1.66667"
              transform="rotate(-90 31 45.3336)"
              fill="white"
            />
            <circle
              cx="31"
              cy="1.66683"
              r="1.66667"
              transform="rotate(-90 31 1.66683)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="45.3336"
              r="1.66667"
              transform="rotate(-90 45.6667 45.3336)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="1.66683"
              r="1.66667"
              transform="rotate(-90 45.6667 1.66683)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 60.3333 45.3338)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 60.3333 1.66707)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 88.6667 45.3338)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 88.6667 1.66707)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 117.667 45.3338)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 117.667 1.66707)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 74.6667 45.3338)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 74.6667 1.66707)"
              fill="white"
            />
            <circle
              cx="103"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 103 45.3338)"
              fill="white"
            />
            <circle
              cx="103"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 103 1.66707)"
              fill="white"
            />
            <circle
              cx="132"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 132 45.3338)"
              fill="white"
            />
            <circle
              cx="132"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 132 1.66707)"
              fill="white"
            />
          </svg>
        </div>
        <div className="absolute hidden md:block top-0 right-16 z-[-1]">
          <svg
            width="134"
            height="106"
            viewBox="0 0 134 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="1.66667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 1.66667 104)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="104"
              r="1.66667"
              transform="rotate(-90 16.3333 104)"
              fill="white"
            />
            <circle
              cx="31"
              cy="104"
              r="1.66667"
              transform="rotate(-90 31 104)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 45.6667 104)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="104"
              r="1.66667"
              transform="rotate(-90 60.3333 104)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 88.6667 104)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 117.667 104)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="104"
              r="1.66667"
              transform="rotate(-90 74.6667 104)"
              fill="white"
            />
            <circle
              cx="103"
              cy="104"
              r="1.66667"
              transform="rotate(-90 103 104)"
              fill="white"
            />
            <circle
              cx="132"
              cy="104"
              r="1.66667"
              transform="rotate(-90 132 104)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="89.3333"
              r="1.66667"
              transform="rotate(-90 1.66667 89.3333)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="89.3333"
              r="1.66667"
              transform="rotate(-90 16.3333 89.3333)"
              fill="white"
            />
            <circle
              cx="31"
              cy="89.3333"
              r="1.66667"
              transform="rotate(-90 31 89.3333)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="89.3333"
              r="1.66667"
              transform="rotate(-90 45.6667 89.3333)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 60.3333 89.3338)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 88.6667 89.3338)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 117.667 89.3338)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 74.6667 89.3338)"
              fill="white"
            />
            <circle
              cx="103"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 103 89.3338)"
              fill="white"
            />
            <circle
              cx="132"
              cy="89.3338"
              r="1.66667"
              transform="rotate(-90 132 89.3338)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="74.6673"
              r="1.66667"
              transform="rotate(-90 1.66667 74.6673)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="31.0003"
              r="1.66667"
              transform="rotate(-90 1.66667 31.0003)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 16.3333 74.6668)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="31.0003"
              r="1.66667"
              transform="rotate(-90 16.3333 31.0003)"
              fill="white"
            />
            <circle
              cx="31"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 31 74.6668)"
              fill="white"
            />
            <circle
              cx="31"
              cy="31.0003"
              r="1.66667"
              transform="rotate(-90 31 31.0003)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 45.6667 74.6668)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="31.0003"
              r="1.66667"
              transform="rotate(-90 45.6667 31.0003)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 60.3333 74.6668)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 60.3333 31.0001)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 88.6667 74.6668)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 88.6667 31.0001)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 117.667 74.6668)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 117.667 31.0001)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 74.6667 74.6668)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 74.6667 31.0001)"
              fill="white"
            />
            <circle
              cx="103"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 103 74.6668)"
              fill="white"
            />
            <circle
              cx="103"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 103 31.0001)"
              fill="white"
            />
            <circle
              cx="132"
              cy="74.6668"
              r="1.66667"
              transform="rotate(-90 132 74.6668)"
              fill="white"
            />
            <circle
              cx="132"
              cy="31.0001"
              r="1.66667"
              transform="rotate(-90 132 31.0001)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 1.66667 60.0003)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 1.66667 16.3336)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 16.3333 60.0003)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 16.3333 16.3336)"
              fill="white"
            />
            <circle
              cx="31"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 31 60.0003)"
              fill="white"
            />
            <circle
              cx="31"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 31 16.3336)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 45.6667 60.0003)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 45.6667 16.3336)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 60.3333 60.0003)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 60.3333 16.3336)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 88.6667 60.0003)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 88.6667 16.3336)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 117.667 60.0003)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 117.667 16.3336)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 74.6667 60.0003)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 74.6667 16.3336)"
              fill="white"
            />
            <circle
              cx="103"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 103 60.0003)"
              fill="white"
            />
            <circle
              cx="103"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 103 16.3336)"
              fill="white"
            />
            <circle
              cx="132"
              cy="60.0003"
              r="1.66667"
              transform="rotate(-90 132 60.0003)"
              fill="white"
            />
            <circle
              cx="132"
              cy="16.3336"
              r="1.66667"
              transform="rotate(-90 132 16.3336)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="45.3336"
              r="1.66667"
              transform="rotate(-90 1.66667 45.3336)"
              fill="white"
            />
            <circle
              cx="1.66667"
              cy="1.66683"
              r="1.66667"
              transform="rotate(-90 1.66667 1.66683)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="45.3336"
              r="1.66667"
              transform="rotate(-90 16.3333 45.3336)"
              fill="white"
            />
            <circle
              cx="16.3333"
              cy="1.66683"
              r="1.66667"
              transform="rotate(-90 16.3333 1.66683)"
              fill="white"
            />
            <circle
              cx="31"
              cy="45.3336"
              r="1.66667"
              transform="rotate(-90 31 45.3336)"
              fill="white"
            />
            <circle
              cx="31"
              cy="1.66683"
              r="1.66667"
              transform="rotate(-90 31 1.66683)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="45.3336"
              r="1.66667"
              transform="rotate(-90 45.6667 45.3336)"
              fill="white"
            />
            <circle
              cx="45.6667"
              cy="1.66683"
              r="1.66667"
              transform="rotate(-90 45.6667 1.66683)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 60.3333 45.3338)"
              fill="white"
            />
            <circle
              cx="60.3333"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 60.3333 1.66707)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 88.6667 45.3338)"
              fill="white"
            />
            <circle
              cx="88.6667"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 88.6667 1.66707)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 117.667 45.3338)"
              fill="white"
            />
            <circle
              cx="117.667"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 117.667 1.66707)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 74.6667 45.3338)"
              fill="white"
            />
            <circle
              cx="74.6667"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 74.6667 1.66707)"
              fill="white"
            />
            <circle
              cx="103"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 103 45.3338)"
              fill="white"
            />
            <circle
              cx="103"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 103 1.66707)"
              fill="white"
            />
            <circle
              cx="132"
              cy="45.3338"
              r="1.66667"
              transform="rotate(-90 132 45.3338)"
              fill="white"
            />
            <circle
              cx="132"
              cy="1.66707"
              r="1.66667"
              transform="rotate(-90 132 1.66707)"
              fill="white"
            />
          </svg>
        </div>
      </>
      : ''}
    </div>
  );
}

export default CmsImage;
